import Network from '@/services/network'
import {useQuery} from '../utils/query'
import {useCreateFormData} from './form-data/bill-pay/useFormData'
import {ToWords} from "to-words";

export default function handlePurchaseAndSales() {
    const network = new Network;
    const {allParams,getQueryString} = useQuery(20)
    const {formData, salesFormData, prepareFormData, prepareSalesFormData} = useCreateFormData()

    const fetchProductList = (query) => {
        return network.api('get', '/inventory/products/all'+query);
    }
    const fetchAccountHead = (query) => {
        return network.api('get', '/account-heads'+query);
    }
    const fetchAccountPayable = (query) => {
        return network.api('get', '/inventory/accounts-payable'+query);
    }
    const fetchAccountReceivable = (query) => {
        return network.api('get', '/inventory/accounts-receivable'+query);
    }
    const fetchVatPayable = (query) => {
        return network.api('get', '/inventory/vat-payable'+query);
    }
    const updateBill = (purchaseMasterId, data) => {
        return network.api('put', `/inventory/purchases/bills/${purchaseMasterId}`, data);
    }
    const storeBill = (data) => {
        return network.api('post', '/inventory/purchases/bills', data);
    }
    const storeBillPayment = (data) => {
        return network.api('post', '/inventory/sales/invoices', data);
    }
    const fetchPurchaseList = (query) => {
        return network.api('get', `/inventory/purchases/bills${getQueryString()}`);
    }
    const fetchSalesList = (query) => {
        return network.api('get', `/inventory/sales/invoices${getQueryString()}`);
    }
    const fetchDueList = (query) => {
        return network.api('get', 'inventory/purchases/payment/due'+query);
    }
    const fetchInvoiceDueList = (query) => {
        return network.api('get', 'inventory/sales/receipt/due'+query);
    }
    const storePayment = (data) => {
        return network.api('post', 'inventory/purchases/payment/create', data)
    }
    const storeInvoice = (data) => {
        return network.api('post', 'inventory/sales/receipt/create', data)
    }

    const fetchPurchaseBill = (purchaseMasterId, query = '') => {
        return network.api('get', `/inventory/purchases/bills/${purchaseMasterId}`+query);
    }

    const fetchSalesBill = (salesId, query = '') => {
        return network.api('get', `/inventory/sales/invoices/${salesId}`+query);
    }

    const updateSalesBill = (salesId, data) => {
        return network.api('put', `/inventory/sales/invoices/${salesId}`, data);
    }
    const addPaymentKeyWithBill = (bills) => {
       return bills.map(bill => {
           bill.payment = ''
           return bill
       })
    }

    const fetchSinglePayment = (paymentId, query) => {
        return network.api('get', `/inventory/purchases/payment/${paymentId}` + query);
    }

    const updateBillPayment = (paymentMasterId, data) => {
        return network.api('put', `/inventory/purchases/payment/${paymentMasterId}`, data);
    }

    const fetchMoneyReceipt = (receiptId, query) => {
        return network.api('get', `/inventory/sales/receipt/${receiptId}` + query);
    }

    const updateMoneyReceipt = (receiptId, data) => {
        return network.api('put', `/inventory/sales/receipt/${receiptId}`, data);
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', {
            minimumFractionDigits: 2
        }).format(value);
    }

    const fetchProductStock = (productId, query) => {
        return network.api('get', `/inventory/products/${productId}/stock` + query);
    }

    const currencyToWord = (value) => {
        const toWords = new ToWords({
            localeCode: 'en-BD',
            converterOptions: {
                currency: true,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: false,
            }
        });
        return toWords.convert(value);
    }

    return {
        storeBill,
        fetchProductList,
        fetchAccountHead,
        fetchAccountPayable,
        fetchAccountReceivable,
        fetchVatPayable,
        storeBillPayment,
        fetchSalesList,
        fetchPurchaseList,
        fetchDueList,
        fetchInvoiceDueList,
        formData,
        salesFormData,
        storePayment,
        storeInvoice,
        prepareFormData,
        prepareSalesFormData,
        addPaymentKeyWithBill,
        allParams,
        fetchPurchaseBill,
        updateBill,
        fetchSinglePayment,
        updateBillPayment,
        fetchMoneyReceipt,
        updateMoneyReceipt,
        fetchSalesBill,
        updateSalesBill,
        formatCurrency,
        currencyToWord,
        fetchProductStock
    }
}
